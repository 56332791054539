import axios from "axios";
import { err_msg } from "helper";
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
  withCredentials: false,
});

export const toggle_popup = (bool, tipe) => ({
  type: "TOGGLE_POPUP",
  bool,
  tipe,
});

export const toggle_loader = (data) => ({
  type: "TOGGLE_LOADER",
  data: data,
});

export const toggle_loader_hash = (data) => ({
  type: "TOGGLE_LOADER_HASH",
  data: data,
});

export const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
});

export const put_form = (group, key, data, idx) => ({
  type: "PUT_FORM",
  group,
  key,
  data,
  idx,
});

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    axios
      .get("/oauth/check_login/")
      .then((resp) => {
        dispatch(put_data("login", resp.data?.user));
        window.localStorage.setItem("nickname", resp.data?.user?.codm_nickname);

        dispatch(get_tournament());
      })
      .catch((err) => {
        // if (err?.response?.data?.message === "not_logged_in") {
        //   // window.location.href = `${process.env.REACT_APP_API_ENDPOINT}oauth/login/`;
        // }
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => toggle_loader(false));
  };
};

export const get_tournament = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get("/api/tournament/")
      .then((resp) => {
        dispatch(put_data("tournament", resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const get_provinces = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get("/api/province/")
      .then((resp) => {
        dispatch(put_data("provinces", resp.data?.province ?? []));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const get_registration = (max_participant) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get("/api/registration/")
      .then((resp) => {
        // console.log(max_participant);
        if (resp?.data?.team_id) {
          dispatch(put_data("form", resp.data));
        } else {
          let member_form = [];
          if (max_participant && max_participant > 0) {
            for (var i = 0; i < max_participant + 1; i++) {
              let data = {
                id: i,
                full_name: "",
                phone_number: "",
                t_open_id: "",
                nickname: "",
                province: "",
                city: "",
                discord_id: "",
                email: "",
                is_leader: i === 0 ? true : false,
                is_reserve: false,
              };
              member_form.push(data);
            }
            dispatch({ type: "UPDATE_MEMBER", data: member_form });
          }
        }
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const post_registration = (type, max_participant, lang) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));

    const { form, login } = getState().main;
    const payload = {
      team_name: form?.team_name,
      members: form?.members.map((member) => {
        delete member?.id;
        delete member?.nickname;
        if (lang !== "ID") {
          delete member.province;
          delete member.city;
        }
        return member;
      }),
    };
    payload.members[0].t_open_id = login?.t_open_id;
    // console.log(payload.members);
    if (max_participant > 1) {
      payload.members.pop();
      payload.members[payload.members.length - 1]["is_reserve"] = true;
    }

    const subtitute = payload.members[payload.members.length - 1];
    if (
      !subtitute.full_name &&
      !subtitute.phone_number &&
      !subtitute.province &&
      !subtitute.city &&
      !subtitute.t_open_id &&
      !subtitute.discord_id &&
      !subtitute.email
    ) {
      payload.members.pop();
    } else {
      subtitute.full_name = subtitute.full_name || "";
      subtitute.phone_number = subtitute.phone_number || "";
      subtitute.province = subtitute.province || "";
      subtitute.city = subtitute.city || "";
      subtitute.t_open_id = subtitute.t_open_id || "";
      subtitute.discord_id = subtitute.discord_id || "";
      subtitute.email = subtitute.email || "";
    }

    axios
      .post("/api/registration/", payload)
      .then((resp) => {
        dispatch(put_data("error_form", {}));
        dispatch(get_registration(payload.members.length));
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        if (
          message !== "invalid_param" &&
          message !== "invalid_input" &&
          message !== "duplicate_entry"
        ) {
          dispatch(catch_error(err_msg(err)));
        }
        dispatch(put_data("error_form", err?.response?.data));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const put_registration = (type, max_participant, lang) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));

    // const lastMemberIdx = type === "mp" ? 5 : 4;
    const lastMemberIdx = max_participant ? max_participant - 1 : 0;
    const { form } = getState().main;
    if (form?.members?.length === lastMemberIdx + 1) {
      const subtitute = form.members[lastMemberIdx];
      if (
        !subtitute.full_name &&
        !subtitute.phone_number &&
        !subtitute.province &&
        !subtitute.city &&
        !subtitute.t_open_id &&
        !subtitute.discord_id &&
        !subtitute.email
      ) {
        form?.members?.pop();
      }
    }

    const payload = {
      ...form,
      members: form?.members.map((member, idx) => {
        delete member.created_at;
        delete member.updated_at;
        delete member.nickname;

        if (!member?.id) {
          member.id = 0;
          member.is_leader = false;
          member.team_id = form?.team_id;
          member.phone_number = member.phone_number || "";
          member.province = member.province || "";
          member.city = member.city || "";
          member.discord_id = member.discord_id || "";
          member.email = member.email || "";
          if (lang !== "ID") {
            delete member.province;
            delete member.city;
          }
        }
        if (idx === lastMemberIdx && max_participant > 1) {
          member.full_name = member.full_name || "";
          member.phone_number = member.phone_number || "";
          member.province = member.province || "";
          member.city = member.city || "";
          member.t_open_id = member.t_open_id || "";
          member.discord_id = member.discord_id || "";
          member.email = member.email || "";
          member.is_reserve = true;
          if (lang !== "ID") {
            delete member.province;
            delete member.city;
          }
        } else {
          member.is_reserve = false;
        }
        return member;
      }),
    };

    axios
      .put("/api/registration/", payload)
      .then((resp) => {
        dispatch(put_data("error_form", {}));
        dispatch(get_registration(max_participant));
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        if (
          message !== "invalid_param" &&
          message !== "invalid_input" &&
          message !== "duplicate_entry"
        ) {
          dispatch(catch_error(err_msg(err)));
        }
        dispatch(put_data("error_form", err?.response?.data));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const delete_team = (max_participant) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .delete("/api/registration/")
      .then((resp) => {
        dispatch(login());
        dispatch(get_registration(max_participant));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const get_nickname = (tOpenId, idx) => {
  return (dispatch) => {
    axios
      .get(`/api/team_member/?t_open_id=${tOpenId}`)
      .then((resp) => {
        dispatch(put_form("members", "nickname", resp?.data?.nickname, idx));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const delete_member = (memberId, idx, max_participant) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { login, form } = getState().main;

    axios
      .delete(`/api/team_member/${memberId}/`)
      .then((resp) => {
        dispatch(get_registration(max_participant));
        if (login?.t_open_id === form?.members[idx]?.t_open_id) {
          window.location.reload();
        }
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const get_join = (max_participant) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get("/api/join_event/")
      .then((resp) => {
        // console.log(max_participant);
        const { members } = resp.data;
        dispatch(put_data("join_data", resp.data));
        if (members.length <= 0) {
          let member_join_data = [];
          if (max_participant && max_participant > 0) {
            for (var i = 0; i < max_participant; i++) {
              let join_data = {
                id: i,
                t_open_id: "",
                is_leader: i === 0 ? true : false,
                is_reserve: false,
                nickname: "",
              };
              member_join_data.push(join_data);
            }
            dispatch({ type: "UPDATE_MEMBER_JOIN", data: member_join_data });
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message !== "user_doesnt_exist") {
          dispatch(catch_error(err_msg(err)));
        }
      })
      .finally(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const post_join = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .post("/api/join_event/")
      .then((resp) => {
        dispatch(login());
        dispatch(get_join());
        dispatch(toggle_popup(true, "registration"));
        dispatch(put_data("popup_message", "registration_success"));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

export const delete_join = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .delete("/api/join_event/")
      .then((resp) => {
        dispatch(login());
        dispatch(get_join());
      })
      .catch((err) => {
        console.log(err?.response);
        dispatch(catch_error(err_msg(err)));
      })
      .finally(() => dispatch(toggle_loader(false)));
  };
};

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (lang = "EN") => {
  return (dispatch) => {
    const main_localize = cdn.get(`${lang}.json?${Math.random() * 10000}`);
    const common_localize = common.get(
      `${lang}.json?${Math.floor(Math.random() * 10000)}`
    );

    axios
      .all([main_localize, common_localize])
      .then((resp) => {
        const json = {
          ...resp[0].data,
          ...resp[1].data,
        };
        dispatch(put_data("lang_json", json));
      })
      .catch((err) => {});
  };
};

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
  const err_message = {
    41: `[err: ${err_msg}] Please logout your account from the game and login again to enter the Event`,
    70: "Please logout your account from SETTINGS",
  };

  return (dispatch) => {
    if (err_msg) {
      if (err_msg === "41" || err_msg === "70") {
        dispatch(put_data("closepopup", false));
        dispatch(put_data("err_msg", err_message[err_msg]));
        dispatch(toggle_popup(true, "error"));
      } else {
        dispatch(put_data("err_msg", err_msg));
        dispatch(toggle_popup(true, "error"));
      }
    }
    dispatch(toggle_loader(false));

    if (err_msg === "invalid_region") {
      localStorage.clear();
    }
  };
};
