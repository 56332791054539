import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_member,
  delete_team,
  put_data,
  toggle_popup,
} from "redux/actions/main";

export const Modals = () => {
  const dispatch = useDispatch();
  const {
    popup_open,
    popup_type,
    popup_message,
    err_msg,
    lang_json,
    closepopup,
    delete_member_data,
    tournament
  } = useSelector((state) => state.main);

  const onCloseModal = () => {
    if (err_msg && err_msg === "not_logged_in") return;
    if(closepopup){
      dispatch(put_data("popup_message", ""));
      dispatch(put_data("err_msg", ""));
      dispatch(toggle_popup(false, ""));
    }
  };

  const confirmModal = () => {
    switch (popup_type) {
      case "deleteTeam":
        dispatch(delete_team(tournament?.max_participant));
        dispatch(toggle_popup(false));
        window.location.reload();
        break;

      case "deleteMember":
        dispatch(
          delete_member(delete_member_data?.id, delete_member_data?.idx, tournament?.max_participant)
        );
        dispatch(toggle_popup(false));
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      classNames={{
        modal: "main-modal",
      }}
      open={popup_open}
      animationDuration={250}
      center
      focusTrapped={false}
      closeOnEsc={false}
      onClose={onCloseModal}
      blockScroll={false}
      closeIcon={null}
    >
      <div className="main-modal__header">
        <h3>{lang_json[popup_type + "_txt"] || lang_json?.registration_txt}</h3>
        <div className="btn-close" onClick={onCloseModal} />
      </div>
      <div className="main-modal__content">
        {err_msg
          ? lang_json[err_msg] || err_msg
          : lang_json[popup_message] || popup_message}
        {popup_type === "deleteTeam" || popup_type === "deleteMember" ? (
          <div className="confirm-modal">
            <button className="btn-orange" onClick={confirmModal}>
              {lang_json?.next_txt}
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
